import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wordpressPage
    let metaDescription = page.yoast_meta.find(x => x.name === 'description') ? page.yoast_meta.find(x => x.name === 'description').content : '';

    return (
      <>
        <SEO title={he.decode(page.yoast_title)} bodyClass={`page page--${page.slug}`} description={metaDescription} />
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => RenderBlock(el.__typename, el, i)) }
      </>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPage(id: {eq: $id}) {
      title
      slug
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      acf {
        feature_image {
          ...original
        }
        content_blocks_page {
          __typename
          ... on WordPressAcf_related_posts {
            posts {
              post {
                type:post_type
                title:post_title
                slug:post_name
              }
              description
            }
          }
          ... on WordPressAcf_pullout_text_module {
            first_column
            second_column
          }
          ... on WordPressAcf_projects_archive_module {
            intro_text
          }
          ... on WordPressAcf_expertise_module {
            fields: wordpress_fields {
              expertise
              content
              image {
                ...original
              }
            }
          }
          ... on WordPressAcf_services_module {
            title
            services {
              title
              content
            }
          }
          ... on WordPressAcf_large_center_text_module {
            text
          }
          ... on WordPressAcf_staff_member_module {
            team_members {
              slug: post_name
              acf {
                name
                job_title
                feature_image {
                  ...original
                }
              }
            }
          }
          ... on WordPressAcf_homepage_columns {
            items {
              size
              post {
                title: post_title
                slug: post_name
                type: post_type
                acf {
                  feature_image {
                    ...original
                  }
                }
              }
            }
          }

          ... on WordPressAcf_project_description_text_module {
            primary_text
            secondary_text
          }
          ... on WordPressAcf_image_text_module {
            content
            side
            image {
              ...original
            }
          }
          ... on WordPressAcf_module_image_columns {
            images {
              image {
                ...original
              }
              size
            }
          }
          ... on WordPressAcf_slideshow_module {
            images {
              ...original
            }
          }
          ... on WordPressAcf_flexible_footer_module {
            lists {
              title
              items {
                text
                url
              }
            }
          }
          ... on WordPressAcf_form_text_module {
            primary_content
            secondary_content
          }
          ... on WordPressAcf_map_module {
            latitude
            longitude
          }
        }
      }
    }
  }
`
